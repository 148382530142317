import badminton from "../../../assets/img/img-animations/01_badminton.png";
import venteFromageVin from "../../../assets/img/img-animations/02_venteFromageVin.png";
import tournoiInterne from "../../../assets/img/img-animations/04_tennis-raquettes.png";
import assembleeGenerale from "../../../assets/img/img-news/ag-450x300.png";
// import assembleeGenerale from "../../../assets/img/img-news/ag-2023-tc-450x300.png";
import pickleball from "../../../assets/img/img-animations/06_pickleball.png";
import ecoleTennis from "../../../assets/img/img-animations/07_ecoleTennis.png";
import soireeClub from "../../../assets/img/img-animations/08_soireeClub.png";
import rolandGarros from "../../../assets/img/img-animations/09_rolandGarros.png";
// import inscriptions2024 from "../../../assets/img/img-news/adhesion-450x300.png";
import inscriptions20251 from "../../../assets/img/img-news/adhesionJeunes1.jpg";
import inscriptions20252 from "../../../assets/img/img-news/adhesionJeunes2.jpg";
import retardataires from "../../../assets/img/img-news/retardataire-450x300.png";
import repriseCours from "../../../assets/img/img-news/repriseCours-450x300.png";

const tabActivitesClub = [
  {
    id: 0,
    image: retardataires,
    animation: "Adhésions",
    desc: "Les inscriptions aux cours continuent",
    dateDebut: "2023-09-16",
    dateFin: "2023-09-30",
    lieu: "Clubhouse",
    link: "/contact",
  },
  {
    id: 1,
    image: assembleeGenerale,
    animation: "Assemblée Générale",
    desc: "En tant que membres du club vous êtes conviés. Venez nombreux ...",
    dateDebut: "2024-10-11",
    dateFin: "2024-10-11",
    lieu: "Clubhouse de 19h à 20h",
    link: "/accueil",
  },
  {
    id: 2,
    image: pickleball,
    animation: "Pickleball",
    desc: "Journée détente en pratiquant une activité accessible à tous ...",
    dateDebut: "2023-11-11",
    dateFin: "2023-11-11",
    lieu: "Courts couverts",
    link: "/animations/pickleball",
  },
  {
    id: 3,
    image: soireeClub,
    animation: "Repas de fin d'année",
    desc: "Tous les membres intéressés sont invités. Faites-vous connaître ...",
    dateDebut: "2023-12-15",
    dateFin: "2023-12-15",
    lieu: "Clubhouse",
    link: "/animations/soireeclub",
  },
  {
    id: 4,
    image: venteFromageVin,
    animation: "Vente de Fromages du Jura - Vins du Beaujolais",
    desc: "Commandes de Noël à passer avant le 13 décembre ...",
    dateDebut: "2023-12-23",
    dateFin: "2023-12-23",
    lieu: "Maison des associations",
    link: "/animations/ventes",
  },
  {
    id: 5,
    image: tournoiInterne,
    animation: "Doubles de Noël",
    desc: "Compétition amicale avec changement de partenaire à chaque double ...",
    dateDebut: "2023-12-26",
    dateFin: "2024-01-06",
    lieu: "Courts couverts",
    link: "/competions",
  },
  {
    id: 6,
    image: badminton,
    animation: "Tournoi de Badminton",
    desc: "Tournoi de la St Patrick - Ouvert à tous. Venez nombreux avec vos amis ...",
    dateDebut: "2024-03-16",
    dateFin: "2024-03-16",
    lieu: "Salle polyvalente",
    link: "/animations/badminton",
  },
  {
    id: 7,
    image: venteFromageVin,
    animation: "Vente de Fromages du Jura - Vins du Beaujolais",
    desc: "Commandes à passer avant le 15 mars ...",
    dateDebut: "2024-04-01",
    dateFin: "2024-04-01",
    lieu: "Maison des associations",
    link: "/animations/ventes",
  },
  {
    id: 8,
    image: rolandGarros,
    animation: "Sortie Roland Garros",
    desc: "Organisation du covoiturage pour une journée commune à RG ...",
    dateDebut: "2024-06-02",
    dateFin: "2024-06-02",
    lieu: "Départ clubhouse – 6h",
    link: "/accueil",
  },
  {
    id: 9,
    image: tournoiInterne,
    animation: "39ème tournoi open NC à 4-6",
    desc: "Tournoi homologué ouvert à tous les licenciés ...",
    dateDebut: "2024-06-07",
    dateFin: "2024-06-23",
    lieu: "Courts extérieurs",
    link: "/accueil",
  },
  {
    id: 10,
    image: soireeClub,
    animation: "Fête du Tennis Club",
    desc: "Repas de fin d'année entre membres et amis du club ...",
    dateDebut: "2024-06-29",
    dateFin: "2024-06-29",
    lieu: "Clubhouse",
    link: "/animations/soireeclub",
  },
  {
    id: 11,
    image: ecoleTennis,
    animation: "Stages de printemps - Enfants",
    desc: "Semaine de stage pour enfants. Inscrivez-vous auprès de Fréd ...",
    dateDebut: "2024-04-15",
    dateFin: "2024-04-19",
    lieu: "Courts extérieurs et couverts",
    link: "/enseignements",
  },
  {
    id: 12,
    image: ecoleTennis,
    animation: "Stages d'été - Enfants",
    desc: "Première semaine de stage pour enfants. Inscrivez-vous auprès de Fréd ...",
    dateDebut: "2024-07-08",
    dateFin: "2024-07-12",
    lieu: "Courts extérieurs et couverts",
    link: "/enseignements",
  },
  {
    id: 13,
    image: ecoleTennis,
    animation: "Stages d'été - Enfants",
    desc: "Deuxième semaine de stage pour enfants. Inscrivez-vous auprès de Fréd ...",
    dateDebut: "2024-07-15",
    dateFin: "2024-07-19",
    lieu: "Courts extérieurs et couverts",
    link: "/enseignements",
  },
  {
    id: 14,
    image: ecoleTennis,
    animation: "Stages d'été - Enfants",
    desc: "Troisième semaine de stage pour enfants. Inscrivez-vous auprès de Fréd ...",
    dateDebut: "2024-07-22",
    dateFin: "2024-07-26",
    lieu: "Courts extérieurs et couverts",
    link: "/enseignements",
  },
  // {
  //   id: 15,
  //   image: soireeClub,
  //   animation: "Jumelage TC Bad Waldsee",
  //   desc: "Réception du tennis club de Bad Waldsee",
  //   dateDebut: "2024-09-20",
  //   dateFin: "2024-09-22",
  //   lieu: "Clubhouse",
  //   link: "/animations/soireeclub",
  // },
  {
    id: 16,
    image: inscriptions20251,
    animation: "Adhésions 2025",
    desc: "Les insciptions auront lieu de 10h à 12h et 14h à 18h",
    dateDebut: "2024-08-31",
    dateFin: "2024-09-07",
    lieu: "Clubhouse",
    link: "/contact",
  },
  {
    id: 17,
    image: repriseCours,
    animation: "Reprise des cours",
    desc: "Les cours reprendront à partir du lundi 16/9",
    dateDebut: "2024-09-16",
    dateFin: "2025-06-27",
    lieu: "Courts extérieurs ou couverts",
    link: "/contact",
  },
  // {
  //   id: 18,
  //   image: retardataires,
  //   animation: "Adhésions",
  //   desc: "Les inscriptions aux cours continuent",
  //   dateDebut: "2024-09-16",
  //   dateFin: "2025-06-27",
  //   lieu: "Courts extérieurs ou couverts",
  //   link: "/contact",
  // },
  {
    id: 19,
    image: pickleball,
    animation: "Pickleball",
    desc: "Journée détente en pratiquant une activité accessible à tous ...",
    dateDebut: "2024-02-10",
    dateFin: "2024-02-10",
    lieu: "Courts couverts",
    link: "/animations/pickleball",
  },
  {
    id: 20,
    image: inscriptions20252,
    animation: "Adhésions 2025",
    desc: "Les insciptions auront lieu de 10h à 12h durant le forum des assos.",
    dateDebut: "2024-09-07",
    dateFin: "2024-09-07",
    lieu: "Salle polyvalente",
    link: "/contact",
  },
];

export default tabActivitesClub;
